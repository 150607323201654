.skillsets__container{
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 2rem;
}

.skillsets__container > div{ 
    background: var(--color-bg-variant);
    padding: 2.4rem 5rem;
    border-radius: 2rem;
    border: 1px solid transparent;
    transition: var(--transition);
}

.skillsets__container > div:hover{
    background: transparent;
    border-color: var(--color-primary-variant);
    cursor: default;
}

.skillsets__container > div h3{
    text-align: center;
    margin-bottom: 2rem;
    color: var(--color-primary);
}

.skillsets__content{
    display: grid;
    grid-template-columns: 1fr 1fr;
    row-gap: 2rem;
}

.skillsets__details{
    display: flex;
    gap: 1rem;
}

.skillsets__details-icon{
    margin-top: 6px;
    color: var(--color-primary);
}

/* ================================== MEDIA QUERIES (MEDIUM DEVICES) ============================== */
@media screen and (max-width: 1024px){
    /* .container{
        background: red; 
    } */

    .skillsets__container{
        grid-template-columns: 1fr;
    }
    
    .skillsets__content{
        padding: 1rem;
    }
}

/* ================================== MEDIA QUERIES (SMALL DEVICES) ============================== */
@media screen and (max-width: 600px){

   .skillsets__container{
       gap: 1rem;
   }

   .skillsets__container > div{
       width: 100%;
       padding: 2rem 1rem;
   }
}