.popup{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: rgba(0,0,0,0,2);
    display: flex;
    justify-content: center;
    align-items: center;
}

.popup-inner{
    position: relative;
    padding: 32px;
    width: 100%;
    max-width: 640px;
    background-color: #FFF;
}

.popup-inner .close-btn{
    position: absolute;
    top: 16px;
    right: 16px;
}

/* ================================== MEDIA QUERIES (MEDIUM DEVICES) ============================== */
@media screen and (max-width: 1024px){

}

/* ================================== MEDIA QUERIES (SMALL DEVICES) ============================== */
@media screen and (max-width: 600px){
   
    .popup
    {
        position: fixed;
        top: 0;
        left: 0;
        width: 80%;
        height: 100vh;
        background-color: rgba(0,0,0,0,2);
        display: flex;
        justify-content: center;
        align-items: center;
        padding-left: 3rem;
    }
}
