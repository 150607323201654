nav {
    background: rgba(0,0,0,0.3);
    width: max-content;
    display: block;
    padding: 0.7rem 1.7rem;
    z-index: 2;
    position: fixed; /* move the navbar to the center */
    left: 50%;
    transform: translateX(-50%);
    bottom: 2rem; /* push the navbar down */
    display: flex; /* evenly spread the navbar icons */
    gap: 0.8rem; /* adds gaps between the nav icons */
    border-radius: 3rem; /* curved navbar */
    backdrop-filter: blur(15px);
}

nav a{
    background: transparent;
    padding: 0.9rem; /* padding in the active region */
    border-radius: 50%;
    display: flex;
    color: var(--color-light); /* turn the navbar icons white */
    font-size: 1.1rem; /* slightly increases the font size */
}

nav a:hover{
    background: rgba(0,0,0,0.3);
}

nav a.active{ /* home button is active */
    background: var(--color-primary);
    color: var(--color-bg);
}