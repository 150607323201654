.work__container{
    /* display: fixed; */
    /* grid-template-columns: 35% 50%; */
    /* gap: 15%; */
}

.swiper {
  width: 50%;
  height: 50%;
}

.swiper-slide {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 18px;
  font-size: 22px;
  font-weight: bold;
  color: #fff;
}

.swiper-slide:nth-child(1n) {
  background-color: var(--color-bg-variant);
}

.swiper-slide:nth-child(2n) {
  background-color: var(--color-bg-variant);
}

.swiper-slide:nth-child(3n) {
  background-color: var(--color-bg-variant);
}

.slider__container{
  display: flex;
}

.image{
  width: 50vh;
  height: auto;
  align-items: center;
  padding-left: 10px;
  padding-right: 10px;
}

.work-duration
{
  text-align: center;
}

.work-description{
  padding-top: 2rem;
  text-align:center;
}

/* ================================== MEDIA QUERIES (MEDIUM DEVICES) ============================== */
@media screen and (max-width: 1024px) and (orientation: portrait){
    .slider__container{
      display: grid;
      grid-template-rows: 1fr 1fr;
      align-items: center;
    }

    .swiper {
      width: auto;
      height: 50%;
      margin-left: 3.5rem;
      margin-right: 3.5rem;
    }

    .image__container{
      padding-left: 18rem;
      padding-right: auto;
      display: flex;
      align-items: center;
    }

    .image{
      width: 30vw;
      height: auto;
      align-items: center;
      display: flex;
    }
}

@media screen and (max-width: 1024px) and (orientation: landscape)
{
    .slider__container{
      display: block;
      grid-template-rows: 1fr 1fr;
      align-items: center;
    }


    .swiper {
      width: auto;
      height: 50%;
      margin-left: 3.5rem;
      margin-right: 3.5rem;
    }

    .swiper-slide {
      /* display: grid; */
      /* align-items: center; */
      /* justify-content: center; */
      border-radius: 18px;
      font-size: 22px;
      font-weight: bold;
      color: #fff;
      }

    .image__container{
      padding-left: 20rem;
      padding-right: auto;
      display: flex;
      align-items: center;
    }

    .image{
      width: 15vw;
      height: auto;
      align-items: center;
      display: flex;
    }

}

/* ================================== MEDIA QUERIES (SMALL DEVICES) ============================== */
@media screen and (max-width: 600px){
   .slider__container{
       display: grid;
       grid-template-rows: 1fr 1fr;
       align-items: center;
   }

   .swiper {
     width: auto;
     height: 50%;
   }

   .image__container{
      padding-left: 6rem;
      display: flex;
      align-items: center;
    }

    .image{
      width: 50vw;
      height: auto;
      align-items: center;
    }

   .work-description{
      font-size:12px;
    }
}

@media screen and (max-width: 600px) and (orientation: landscape){
    
  .swiper {
     width: auto;
     height: 80%;
   }
  
   .slider_container{
     height: 10%;
   }

   .swiper-slide {
     display: flex;
     align-items: center;
     justify-content: center;
     border-radius: 18px;
     font-size: 22px;
     font-weight: bold;
     color: #fff;
     }

  .image__container{
    padding-top: 1rem;
    padding-left: 10rem;
    display: flex;
    align-items: center;
    }

    .image{
      width: 10vw;
      height: auto;
      align-items: center;
    }


}